import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { concatMap, delay, Observable, of, retryWhen, throwError } from 'rxjs';

@Injectable()
export class RequestRetryerInterceptor implements HttpInterceptor {
  private readonly _RETRY_COUNT = 2;
  private readonly _DELAY = 1000;

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(
        (
          errors: Observable<HttpErrorResponse>
        ): Observable<HttpErrorResponse> => {
          return errors.pipe(
            concatMap(
              (
                error: HttpErrorResponse,
                count: number
              ): Observable<HttpErrorResponse> => {
                if (count < this._RETRY_COUNT) {
                  if (error.status >= 500) return of(error);
                }
                return throwError((): HttpErrorResponse => error);
              }
            ),
            delay(this._DELAY)
          );
        }
      )
    );
  }
}
