import { SkipSpinnerInterceptor } from '../modules/common/loading/constants';

export const SkipHttpErrorInterceptor = 'Skip-Http-Error-Interceptor';

export const SkipAllHttpErrorInterceptor = 'Skip-All-Http-Error-Interceptor';

export const SkipLanguageInterceptor = 'Skip-Language-Interceptor';

export const HeaderInterceptors: string[] = [
  SkipSpinnerInterceptor,
  SkipHttpErrorInterceptor,
  SkipAllHttpErrorInterceptor,
  SkipLanguageInterceptor,
];
