<section class="language" *ngIf="selectedLanguage">
  <article
    class="language__i18n--selected"
    #languageSelect
    (click)="toggleMenu()"
  >
    <img
      class="flag"
      [src]="selectedLanguage.flag"
      [alt]="selectedLanguage.code"
      alt="flag"
    />
    <span
      class="language__i18n--selected-text"
      [innerHTML]="'i18n.' + selectedLanguage.code | translate"
    ></span>
    <i class="material-icons arrow">arrow_drop_down</i>
  </article>
  <article class="language__i18n" #languageMenu>
    <ul class="language__i18n__select">
      <ng-container *ngFor="let language of languages">
        <li class="language__i18n__option" (click)="onChangeLanguage(language)">
          <img
            class="flag"
            [src]="language.flag"
            [alt]="language.code"
            alt="flag"
          />
          <span [innerHTML]="'i18n.' + language.code | translate"></span>
        </li>
      </ng-container>
    </ul>
  </article>
</section>
