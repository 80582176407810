import { Injectable } from '@angular/core';
import { API_URL } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SkipAllHttpErrorInterceptor } from '../../../constants';
import { SkipSpinnerInterceptor } from '../../../modules/common/loading/constants';
import { PartnerOrderDetail } from '../../../modules/transaction/models';

@Injectable({
  providedIn: 'root',
})
export class BinancePaymentStatusService {
  constructor(private readonly _http: HttpClient) {}

  //#region PUBLIC
  public getBinancePaymentStatus(
    partnerOrderId: string
  ): Observable<PartnerOrderDetail> {
    const url = `${API_URL}public/v1/orders/partner-orders/${partnerOrderId}`;
    const headers = new HttpHeaders({
      [SkipAllHttpErrorInterceptor]: '',
      [SkipSpinnerInterceptor]: '',
      [SkipSpinnerInterceptor]: '',
    });
    return this._http.get<PartnerOrderDetail>(url, { headers: headers });
  }

  //#endregion PUBLIC
}
