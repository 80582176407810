import { APP_INITIALIZER, Provider } from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { from, map, Observable, of, switchMap, tap } from 'rxjs';

import { APP_VERSION } from '../../../environments/environment';
import { WINDOW } from '../../constants';

type Meta = { version: string; history: string[] };

/**
 * Load newest application version.
 * @param {SwUpdate} swUpdate service to hard reload page.
 * @param {HttpBackend} httpBackend to generate an `HttpClient` instance.
 * @param {Window} window reference.
 */
function initializeCacheBuster(
  swUpdate: SwUpdate,
  httpBackend: HttpBackend,
  window: Window
): () => Observable<boolean> {
  const metaFilename = '/meta.json';

  return function (): Observable<boolean> {
    return deleteMetaFromCache().pipe(
      switchMap((): Observable<boolean> => verifyMetaVersion()),
      switchMap((reload: boolean): Observable<boolean> => {
        if (!reload) return of(true);
        if (!window.caches) return updatePWA();
        return reloadCache().pipe(
          switchMap((): Observable<boolean> => updatePWA())
        );
      })
    );
  };

  function deleteMetaFromCache(): Observable<boolean> {
    return from(window.caches.has(metaFilename)).pipe(
      switchMap((isCached: boolean): Observable<boolean> => {
        if (isCached) return from(window.caches.delete(metaFilename));
        return of(false);
      })
    );
  }

  function verifyMetaVersion(): Observable<boolean> {
    const http: HttpClient = new HttpClient(httpBackend);
    const headers: HttpHeaders = new HttpHeaders({
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });
    const params: HttpParams = new HttpParams({
      fromObject: { timestamp: Date.now() },
    });
    return http
      .get<Meta>(metaFilename, { headers, params })
      .pipe(map(({ version }: Meta): boolean => version !== APP_VERSION));
  }

  function updatePWA(): Observable<boolean> {
    return from(swUpdate.checkForUpdate()).pipe(
      switchMap((areThereUpdates: boolean): Observable<boolean> => {
        if (areThereUpdates) return from(swUpdate.activateUpdate());
        return of(areThereUpdates);
      }),
      tap((areThereUpdates: boolean): void => {
        areThereUpdates && window.location.reload();
      })
    );
  }

  function reloadCache(): Observable<boolean[]> {
    return from(window.caches.keys()).pipe(
      switchMap((keys: string[]): Observable<boolean[]> => {
        return from(
          Promise.all(
            keys.map((key: string): Promise<boolean> => {
              return window.caches.delete(key);
            })
          )
        );
      })
    );
  }
}

export const CacheBusterInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeCacheBuster,
  deps: [SwUpdate, HttpBackend, WINDOW],
  multi: true,
};
