import { I18n } from '../models';

export const SUPPORTED_I18N: I18n[] = [
  {
    code: 'en_US',
    flag: 'assets/icons/flags/us.svg',
    default: true,
  },
  {
    code: 'es_419',
    flag: 'assets/icons/flags/es_LA.svg',
    default: false,
  },
];
