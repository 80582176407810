import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';

import { APP_VERSION } from '../environments/environment';
import { LoadingService } from './modules/common/loading/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public subscription!: Subscription;

  public readonly appVersion: string = APP_VERSION;
  public showHeaderAndBanner!: boolean;
  private readonly binanceStatus = '/binance-payment-status';

  constructor(
    private readonly _router: Router,
    private readonly _loadingService: LoadingService
  ) {}

  //#region LIFECYCLE

  public ngOnInit(): void {
    this.subscription = this._handleSpinnerWhenRouting();
  }

  //#endregion LIFECYCLE

  //#region PRIVATE

  private _handleSpinnerWhenRouting(): Subscription {
    let currentRoute: string | null = null;

    return this._router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationStart) {
        if (!currentRoute || currentRoute === event.url) {
          currentRoute = event.url;
          return this._loadingService.show(currentRoute);
        }

        if (currentRoute && currentRoute !== event.url) {
          this._loadingService.hide(currentRoute);
          currentRoute = event.url;
          this._loadingService.show(currentRoute);
        }
      } else if (event instanceof NavigationEnd) {
        currentRoute = event.url;
        this._loadingService.hide(currentRoute);
        currentRoute = null;
      }
    });
  }

  private _getBaseURL(): string {
    const fullURL = new URL(this._router.url, window.location.href);
    return fullURL.pathname;
  }

  //#endregion PRIVATE

  //#region PUBLIC
  public onActiveRoute(): void {
    const baseURL = this._getBaseURL();
    this.showHeaderAndBanner = baseURL !== this.binanceStatus;
  }

  //#endregion PUBLIC
}
