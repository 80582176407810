import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { I18nModule } from './modules/common/i18n';
import { SelectI18nModule } from './modules/common/i18n/components/select-i18n';
import { I18nInitializerProvider } from './modules/common/i18n/providers/i18n.initializer';
import { I18nInterceptorProvider } from './modules/common/i18n/providers/i18n.interceptor';
import { ServiceWorkerConfigModule } from './modules/common/service-worker-config';
import { CacheBusterInitializerProvider } from './providers/cache-buster.initializer';
import { NavigatorProvider } from './providers/navigator';
import { WindowProvider } from './providers/window';
import { LoadingComponent } from './modules/common/loading/components/loading.component';
import { LoadingInterceptorProvider } from './modules/common/loading/providers';
import { HttpErrorsHandlerInterceptorProvider } from './modules/common/http-errors-handler/providers';
import { RequestRetryerInterceptorProvider } from './modules/common/request-retryer/providers';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, LoadingComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverlayModule,
    AppRoutingModule,
    ServiceWorkerConfigModule,
    I18nModule,
    SelectI18nModule,
  ],
  providers: [
    NavigatorProvider,
    WindowProvider,
    CacheBusterInitializerProvider,
    I18nInitializerProvider,
    I18nInterceptorProvider,
    LoadingInterceptorProvider,
    HttpErrorsHandlerInterceptorProvider,
    RequestRetryerInterceptorProvider,
  ],
})
export class AppModule {}
