import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { SUPPORTED_I18N } from '../../constants';
import { I18n } from '../../models';
import { I18nService } from '../../services';

@Component({
  selector: 'app-select-i18n',
  templateUrl: './select-i18n.component.html',
  styleUrls: ['./select-i18n.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe
export class SelectI18nComponent implements OnInit {
  @ViewChild('languageMenu')
  private readonly _languageMenu!: ElementRef<HTMLElement>;
  @ViewChild('languageSelect')
  private readonly _selectLanguage!: ElementRef<HTMLElement>;

  public subscription!: Subscription;
  public selectedLanguage!: I18n;

  public get languages(): I18n[] {
    return SUPPORTED_I18N;
  }

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _i18nService: I18nService
  ) {
    this.selectedLanguage = this._i18nService.current;
  }

  //#region LIFECYCLE

  public ngOnInit(): void {
    this.subscription = this._dismissMenuListener();
  }

  //#endregion LIFECYCLE

  //#region PUBLIC

  public onChangeLanguage(language: I18n): void {
    this._i18nService.select(language);
    this.selectedLanguage = language;
    this.toggleMenu();
    this._cdr.detectChanges();
  }

  public toggleMenu(): void {
    this._languageMenu.nativeElement.classList.toggle('show');
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _dismissMenuListener(): Subscription {
    return fromEvent(this._document, 'click').subscribe((event): void => {
      if (
        !this._languageMenu.nativeElement.contains(event.target as Node) &&
        this._languageMenu.nativeElement.classList.contains('show') &&
        !this._selectLanguage.nativeElement.contains(event.target as Node)
      ) {
        this._languageMenu.nativeElement.classList.remove('show');
      }
    });
  }

  //#endregion PRIVATE
}
