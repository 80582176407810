import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  imports: [
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerImmediately',
      enabled: true,
    }),
  ],
  exports: [ServiceWorkerModule],
})
export class ServiceWorkerConfigModule {}
