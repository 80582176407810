import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { SkipI18nInterceptor } from '../../constants';

@Injectable()
export class I18nInterceptor implements HttpInterceptor {
  constructor(private readonly _translate: TranslateService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(SkipI18nInterceptor)) {
      return next.handle(request);
    }
    return next.handle(this._addHeaders(request));
  }

  private _addHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const i18n = (this._translate.currentLang || 'en_US').replace('_', '-');
    return request.clone({
      setHeaders: {
        'Content-Language': i18n,
      },
    });
  }
}
