import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

import {
  SkipAllHttpErrorInterceptor,
  SkipHttpErrorInterceptor,
} from '../../../../constants';

@Injectable()
export class HttpErrorsHandlerInterceptor implements HttpInterceptor {
  constructor(private readonly _router: Router) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(
        (httpError: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
          const applyInterceptor: boolean = this._applyInterceptor(
            request.headers,
            httpError.status
          );
          if (applyInterceptor) {
            this._router.navigate(['error'], {
              queryParams: {
                code: httpError.status,
                message: encodeURIComponent(
                  httpError.error?.humanMessage || httpError.message
                ),
              },
            });
          }
          return throwError((): HttpErrorResponse => httpError) as Observable<
            HttpEvent<unknown>
          >;
        }
      )
    );
  }

  private _applyInterceptor(headers: HttpHeaders, status: number): boolean {
    if (headers.has(SkipAllHttpErrorInterceptor)) return false;
    if (!headers.has(SkipHttpErrorInterceptor)) return true;

    const codeStr: string | null = headers.get(SkipHttpErrorInterceptor);
    if (!codeStr) return true;

    const codes: string[] = codeStr.split(',');
    for (const code of codes) {
      if (+code === status) return false;
    }
    return true;
  }
}
