import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { I18nInterceptor } from '../../interceptors/i18n';

export const I18nInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: I18nInterceptor,
  multi: true,
};
