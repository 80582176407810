import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NAVIGATOR } from '../../../../constants';
import { LocalStorageService } from '../../../../services/storage';
import { I18N, SUPPORTED_I18N } from '../constants';
import { I18n } from '../models';

@Injectable({ providedIn: 'root' })
export class I18nService {
  public get current(): I18n {
    const selection: string | null = this._storageService.get<string>(I18N);
    if (selection) {
      const selectedLang: I18n | undefined = SUPPORTED_I18N.find(
        ({ code }: I18n): boolean => code === selection
      ) as I18n;
      if (selectedLang) return selectedLang;
    }

    const browserLangCode: string = this._navigator.language.replace('-', '_');
    const browserLang: I18n | undefined = SUPPORTED_I18N.find(
      ({ code }: I18n): boolean => code === browserLangCode
    ) as I18n;
    if (browserLang) return browserLang;

    return SUPPORTED_I18N.find((language: I18n): boolean => {
      return language.default;
    }) as I18n;
  }

  constructor(
    @Inject(NAVIGATOR) private readonly _navigator: Navigator,
    private readonly _translateService: TranslateService,
    private readonly _storageService: LocalStorageService
  ) {}

  public init(): void {
    if (!this._translateService.currentLang) {
      this._translateService.addLangs(
        SUPPORTED_I18N.map((i18n: I18n): string => i18n.code)
      );
      this._storageService.set<string>(I18N, this.current.code);
      this._translateService.setDefaultLang(this.current.code);
      this._translateService.use(this.current.code);
    }
  }

  public select(selection: I18n): void {
    this._storageService.set<string>(I18N, selection.code);
    this._translateService.use(selection.code);
  }
}
