<main class="main">
  <ng-container *ngIf="showHeaderAndBanner">
    <section class="banner" [innerHTML]="'test.banner' | translate"></section>
    <section class="header">
      <article class="header-container">
        <div class="header-container-content">
          <div class="zenkipay">
            <img src="assets/img/zenkipay-logo.svg" alt="Zenkipay" />
            <span class="decorator">&nbsp;</span>
            <span class="wallet" [innerHTML]="'wallet' | translate"></span>
          </div>
          <div class="i18n">
            <app-select-i18n></app-select-i18n>
          </div>
        </div>
      </article>
    </section>
  </ng-container>

  <section class="routing">
    <router-outlet (activate)="onActiveRoute()"></router-outlet>
  </section>
</main>
