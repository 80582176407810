import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { BinancePaymentStatusService } from '../service/binance-payment-status.service';
import { PartnerOrderDetail } from '../../../modules/transaction/models';

@Injectable({
  providedIn: 'root',
})
export class BinancePaymentStatusResolver
  implements Resolve<PartnerOrderDetail>
{
  private readonly _orderID: string = 'id';

  constructor(
    private router: Router,
    private readonly _binancePaymentStatusService: BinancePaymentStatusService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<PartnerOrderDetail>
    | Promise<PartnerOrderDetail>
    | PartnerOrderDetail {
    const partnerOrderId = route.queryParamMap.get(this._orderID);

    if (partnerOrderId) {
      return this._binancePaymentStatusService
        .getBinancePaymentStatus(partnerOrderId)
        .pipe(
          catchError((error) => {
            console.error('Error on getBinancePaymentStatus:', error);
            return of(null as unknown as PartnerOrderDetail);
          })
        );
    } else {
      return of(null as unknown as PartnerOrderDetail);
    }
  }
}
