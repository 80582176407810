import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';

import { SkipSpinnerInterceptor } from '../constants';
import { LoadingService } from '../services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Event = any;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly _loadingService: LoadingService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(SkipSpinnerInterceptor)) {
      return next.handle(request);
    }

    this._loadingService.show(request.url);
    return next.handle(request).pipe(
      tap((event: Event): void => {
        if (event instanceof HttpResponse) {
          this._loadingService.hide(request.url);
        }
      }),
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        console.error(
          '%c%s.%s[ERROR]: %o',
          'color: red; font-weight: bolder',
          this.constructor.name,
          this.intercept.name,
          error
        );
        this._loadingService.hide(request.url);
        return throwError((): HttpErrorResponse => error) as Observable<
          HttpEvent<unknown>
        >;
      })
    );
  }
}
