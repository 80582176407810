/**
 * Compare if two objects are strictly equals.
 * @param {any} input1 to compare.
 * @param {any} input2 to compare.
 * @param {number} deepLevelLimit optional, it prevents infinite recursion in circular objects.
 * @returns {boolean} `true` if these are strictly equal.
 */
export function isEqual(
  input1: unknown,
  input2: unknown,
  deepLevelLimit = 7
): boolean {
  function _isEqual(
    param1: unknown,
    param2: unknown,
    currentDeepLevel: number
  ): boolean {
    if (currentDeepLevel > deepLevelLimit) {
      throw new Error(
        `Comparison between objects exceeds deep level limit of ${deepLevelLimit}`
      );
    }

    if ((!param1 && param2) || (param1 && !param2)) return false;

    if (!param1 && !param2) return true;

    if (typeof param1 !== typeof param2) return false;

    if (typeof param1 !== 'object') {
      return param1 === param2;
    }

    if (Array.isArray(param1) && Array.isArray(param2)) {
      for (let i = 0; i < param1.length; i++) {
        const isOK: boolean = _isEqual(
          param1[i],
          param2[i],
          currentDeepLevel + 1
        );
        if (!isOK) return false;
      }
    } else if (
      (Array.isArray(param1) && !Array.isArray(param2)) ||
      (!Array.isArray(param1) && Array.isArray(param2))
    ) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param1Entries: [string, unknown][] = Object.entries(param1 as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param2Entries: [string, unknown][] = Object.entries(param2 as any);

    if (param1Entries.length !== param2Entries.length) return false;

    for (let i = 0; i < param1Entries.length; i++) {
      const [key1, value1]: [string, unknown] = param1Entries[i];
      const index: number = param2Entries.findIndex(
        ([key2]: [string, unknown]): boolean => key2 === key1
      );
      if (index < 0) return false;

      const [, value2]: [string, unknown] = param2Entries.splice(index, 1)[0];
      const isOK: boolean = _isEqual(value1, value2, currentDeepLevel + 1);
      if (!isOK) return false;
    }

    return true;
  }

  return _isEqual(input1, input2, 0);
}

/**
 * Parse string to JSON.
 * @param {string} input to parse.
 * @returns {any} value parsed as JSON.
 */
export function string2Json<T = unknown>(input: string): T {
  try {
    return JSON.parse(input);
  } catch (_) {
    return input as unknown as T;
  }
}

/**
 * Parse JSON to string.
 * @param {any} input to stringify.
 * @returns {string} Input value stringified.
 */
export function json2String<T = unknown>(input: T): string {
  try {
    if (typeof input === 'string') return input;
    return JSON.stringify(input);
  } catch (_) {
    return input as unknown as string;
  }
}

/**
 * Verify if a value is *null* or *undefined*.
 * @param {unknown} input to verify.
 * @returns {boolean} *true* if is *null* or *undefined*.
 */
export function isNil<T = unknown>(input: T): boolean {
  return input === null || input === undefined;
}

export function copy(value: string): void {
  const selectBox = document.createElement('textarea');
  selectBox.style.position = 'fixed';
  selectBox.value = value;
  document.body.appendChild(selectBox);
  selectBox.focus();
  selectBox.select();
  document.execCommand('copy');
  document.body.removeChild(selectBox);
}
