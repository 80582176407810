/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BinancePaymentStatusResolver } from './pages/binance-payment-status/resolver/binance-payment-status.resolver';
import { BinancePaymentGuard } from './pages/binance-payment-status/guards/binance-payment.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/start-page').then((m) => m.StartPageModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment-page').then((m) => m.PaymentPageModule),
  },
  {
    path: 'binance',
    loadChildren: () =>
      import('./pages/payment-binance-page').then(
        (m) => m.PaymentBinancePageModule
      ),
  },
  {
    path: 'binance-payment-status',
    canActivate: [BinancePaymentGuard],
    resolve: { order: BinancePaymentStatusResolver },
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/binance-payment-status').then(
        (m) => m.BinancePaymentStatusModule
      ),
  },
  {
    path: 'payment-done',
    loadChildren: () =>
      import('./pages/payment-done-page').then((m) => m.PaymentDonePageModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found-page').then((m) => m.NotFoundPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
