import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { RequestRetryerInterceptor } from '../interceptors';

export const RequestRetryerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestRetryerInterceptor,
  multi: true,
};
